<template>
  <div class="price-list-view col-lg-12">
    <div class="image-p-list-cover" v-if="currentRouteName !== '/'">
      <div class="image-p-list-cover-text col-lg-7">
        <h1>Procena cene usluga</h1>
        <p v-if="!hideButtons">
          Da saznate više o cenama popunite upitnik.
        </p>
          <p v-else-if="selectedButton">
            Potrebno je popuniti anketu u nastavku kako bi definisali ponudu koja će najviše odgovarati
            potrebama Vašeg poslovanja.
          </p>
      </div>
      <img class=" responsive"  src="../assets/hands.jpg" alt="" style="visibility: hidden;">
    </div>
        <price-list-component-three-buttons
          :class="hideButtons ? 'hide' : '' " 
          :hideButtons="hideButtons" 
          :selectedButton="selectedButton" 
          :currentRouteName="currentRouteName" 
          @handle-hide-buttons="handleHideButtons" 
          @handle-hide-form="handleHideForm"

        />
        <div>

        

        </div>
    <div :class="hideButtons ? '' : 'hide'">
      <div :class="hideSelectedButtons ? 'hide selected-buttons' : 'selected-buttons'">
        <div v-if="selectedButton === 'entrepreneur'"><EntrepreneurComponent @handle-selected-option="handleSelectedOption"/></div>
        <div v-else-if="selectedButton === 'doo'"><DooComponent @handle-selected-option="handleSelectedOption"/></div>
        <div v-else-if="selectedButton === 'association'"><AssociationComponent @handle-selected-option="handleSelectedOption"/></div>
      </div>
      <FormComponent :class="showForm ? '' : 'hide'" @handle-inputs="handleInputs" :errors="errors" :formData="formData" :formValues="formValues" :selectedButton="selectedButton" :selectedFormOption="selectedFormOption"/>
      <div class="col-lg-7 m-auto">
        <div v-if="emailFormMessage" class="error-message m-auto">
          {{emailFormMessage}}
        </div>
        <div v-else></div>
      <re-captcha :class="showForm ? 'right-button captcha' : 'hide captcha'" :siteKey="siteKey" @validate="validate" ref="ReCaptcha"/>
      <button v-if="fromRoute === 'home'" class="col-lg-2 btn btn-danger" @click="goToHome(false)">Idi na početnu</button>
      <button v-else class="col-lg-2 left-button btn btn-danger" @click="handleHideForm(false)">idi nazad</button>
      <button :disabled="isActive" :class="showForm ? 'col-lg-2 right-button btn btn-success' : 'hide'" @click="handleSubmitForm">Pošalji</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import PriceListComponentThreeButtons from '../components/PriceListComponentThreeButtons.vue'
import EntrepreneurComponent from '../components/EntrepreneurComponent.vue'
import DooComponent from '../components/DooComponent.vue'
import AssociationComponent from '../components/AssociationComponent.vue'
import FormComponent from '../components/FormComponent.vue'
import ReCaptcha from '../components/ReCaptcha.vue'
export default {
  components:{
    PriceListComponentThreeButtons,
    EntrepreneurComponent,
    DooComponent,
    AssociationComponent,
    FormComponent,
    ReCaptcha
  },
  data(){
    return {
      defaultValue: {option_text: '/', price: 0, question_text: ''},
      defaultFQ: [ {option_text: '/', price: 0, question_text: ''}],
      siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
      hideButtons: this.$route.params.from === 'home' ? this.$route.params.hideButtons : false,
      selectedButton: this.$route.params.from === 'home' ? this.$route.params.selectedButton : '',
      selectedFormOption: '',
      hideSelectedButtons: false,
      showForm: false,
      isActive: false,
      timeout: null,

      formValues:{
        firstQuestion:[],
        secondQuestion: '',
        fourthQuestion: '',
        fifthQuestion: '',
        sixthQuestion: '',
        seventhQuestion: '',
        eighthQuestion: '',
        thirdQuestion:'',
        ninthQuestion: '',
        email: '',
        comment: ''
      },
      totalPrice:[],
      realTotalPrice:'',
      firstQSum: '',
      questionsForQNine: {},
      removedQuestionOption: {},
      removedPdv:{},
      removedCashRegister:{},
      removedflatRateOption: {},
      ternaryStatement: '',
      //selected data for summing
        selectedFirstQuestion : {
          questionText: '',
          textOptions: [],
          prices: []
        },
        selectedSecondQuestion: {
          questionText: '',
          textOption: '',
          price: []
        },
        selectedThirdQuestion: {
          questionText: '',
          textOption: '',
          price: []
        },
        selectedFourthQuestion:{
          questionText: '',
          textOption: '',
          price: []
        },
        selectedFifthQuestion: {
          questionText: '',
          textOption: '',
          price: []
        },
        selectedSixthQuestion: {
          questionText: '',
          textOption: '',
          price: []
        },
        selectedSeventhQuestion: {
          questionText: '',
          textOption: '',
          price: []
        },
        selectedEighthQuestion: {
          questionText: '',
          textOption: '',
          price: []
        },
        selectedNinthQuestion: {
          questionText: '',
          textOption: '',
          price: []
        },
    }
  },
  computed: {
    ...mapGetters(['formData','validateReCaptcha', 'emailFormMessage', 'errors']),
    // formQuestionsText() {
    //     var questionText= []
    //     this.formData.forEach(data => {
    //     //   data.forEach(realData => {
    //         questionText.push(data)
    //     //   })
    //     })
    //     return questionText
    // },
    currentRouteName() {
      return this.$route.path;
    },
    routeParams(){
      return this.$route.params
    },
    fromRoute() {
      return this.$route.params.from;
    }
    

   
 
  },


  methods:{
    ...mapActions(['getFormData', 'setEmptyFormData','getCaptchaValidate', 'setMailFormData', 'setEmptyEmailFormMessage', 'deleteErrors', 'deleteEmailFormMessage']),

    resetCaptcha(){
      this.$refs.ReCaptcha.reCaptchaReset()
      
    },
    async handleHideButtons(val, bool){
      //val is a string
      this.hideButtons = bool;
      this.selectedButton = val;
    },
    goToHome(val){
      
      // val is boolean
      this.hideButtons = val;
      this.$router.push('/');
    },
     validate(response){
        this.getCaptchaValidate(response);

    },
    async handleSelectedOption(val){
     
      // val is string
      await this.getFormData({businessTypeParam: val});
      this.selectedFormOption = val;
      //HANDLING FORM DATA SO THEY CAN PROPERLY DISPLAYED
      //ALREADY ENTREPRENEUR
      if(val === 'already ent'){
        const flatRateTaxed = this.formData.data.find(x => x.q_id === 24);
        this.removedflatRateOption = flatRateTaxed.question_options.pop();
        const pdvs = this.formData.data.find(x => x.q_id === 26);
        this.removedPdv = pdvs.question_options.pop();
        const cashRegister = this.formData.data.find(x => x.q_id === 32);
        this.removedCashRegister = cashRegister.question_options.pop();
      }
      //ALREADY DOO
      else if(val === 'already doo'){
        const pdvs = this.formData.data.find(x => x.q_id === 44);
        this.removedPdv = pdvs.question_options.pop();
        const cashRegister = this.formData.data.find(x => x.q_id === 50);
        this.removedCashRegister = cashRegister.question_options.pop();
      }
        //ASSOCIATION
      if(this.selectedButton === 'association'){
          if(this.selectedFormOption === 'new assoc'){
            const PersonNumForEmploy = this.formData.data.find(x => x.q_id === 61)
            PersonNumForEmploy.question_options.splice(1,1);
          }else if(this.selectedFormOption === 'already assoc'){
            const PersonNumForEmploy = this.formData.data.find(x => x.q_id === 62)
            PersonNumForEmploy.question_options.shift();
            const pdvs = this.formData.data.find(x => x.q_id === 64)
            this.removedPdv = pdvs.question_options.pop();
            const cashReg = this.formData.data.find(x => x.q_id === 68)
            this.removedCashRegister = cashReg.question_options.pop();
          }
      }
      this.hideSelectedButtons = true;
      this.showForm = true;
    },
    async handleHideForm(val){
      //val is boolean
      await this.setEmptyFormData();
      await this.setEmptyEmailFormMessage()
      this.hideButtons = val;
      this.hideSelectedButtons = val;
      this.showForm = val;
      this.selectedButton = '';
      this.removedQuestionOption = {};

      //selected questions
      this.selectedFirstQuestion = {
          questionText: '',
          textOptions: [],
          prices: []
        },
      this.selectedSecondQuestion = {
          questionText: '',
          textOption: '',
          price: []
        },
      this.selectedThirdQuestion = {
          questionText: '',
          textOption: '',
          price: []
      };
      this.selectedFourthQuestion = {
          questionText: '',
          textOption: '',
          price: []
      };
      this.selectedFifthQuestion = {          
          questionText: '',
          textOption: '',
          price: []
      };
      this.selectedSixthQuestion = {          
          questionText: '',
          textOption: '',
          price: []
      };
      this.selectedSeventhQuestion = {        
            questionText: '',
          textOption: '',
          price: []
      };
      this.selectedEighthQuestion = {         
           questionText: '',
          textOption: '',
          price: []
      };
      this.selectedNinthQuestion = {          
          questionText: '',
          textOption: '',
          price: []
      };
      this.ternaryStatement= '';
      this.firstQSum= '';
      this.totalPrice = [];
      this.realTotalPrice = '';
      this.isActive = false;
      this.$refs.ReCaptcha.reCaptchaReset();
      //emtying formValues 
      for (var key in this.formValues) {
        if(key === 'firstQuestion')this.formValues[key] = [];
        else this.formValues[key] = '';
      }
      this.deleteErrors();
      this.deleteEmailFormMessage()
    },
    async handleSubmitForm(){
      this.deleteErrors();
      this.timeout = setTimeout(() => Object.keys(this.errors).length === 0 ?  this.isActive = true : this.isActive = false, 2000)

      if(this.validateReCaptcha === true){
        this.isActive = true;
      }
      //FINDING SELECTED DATA AND ASSIGNING IT TO PROPERTIES AND CALCULATING PRICE
      //FIRST QUESTION 
        //find first question 
        const firstQuestion = this.formData.data.find(x=> x.name === 'firstQuestion');
          //adding question text
          this.selectedFirstQuestion.questionText = firstQuestion.question_text
          //looping through first question options
          firstQuestion.question_options.forEach(option => {
            //checking if form is for ENTREPRENEUR and DOO
            if (this.selectedButton === 'entrepreneur' || this.selectedButton === 'doo') {
              //looping through form values that are seleted
              this.formValues.firstQuestion.forEach(question => {
                  //comparing selected option id with firstQuestion option text id
                  if(question === option.id){
                      //adding right values to selectedFirstQuestion
                      this.selectedFirstQuestion.textOptions.push(option.option_text)
                      //adding right price to selectedPrice array
                      this.selectedFirstQuestion.prices.push(option.price)
                    
                    }
                })
            } 
            //else if form is for ASSOC
            else{
                //comparing selected option id with firstQuestion option text id
                if(this.formValues.firstQuestion === option.id){
                   //adding right values to selectedFirstQuestion
                  this.selectedFirstQuestion.textOptions.push(option.option_text)
                  //adding right price to selectedPrice array
                  this.selectedFirstQuestion.prices.push(option.price)
                }
            }
          })
        //calculating prices of selectedFirstQuestion
        this.firstQSum = this.selectedFirstQuestion.prices.reduce((a,b) => a + b, 0)
        this.totalPrice.push(this.firstQSum)
      //SECOND QUESTION
      //find second question 
        const secondQuestion = this.formData.data.find(x=> x.name === 'secondQuestion');
        //set question text for selectedSecondQuestion
        this.selectedSecondQuestion.questionText = secondQuestion.question_text
        //looping through secondQuestion options
        secondQuestion.question_options.forEach(option => {
          //check if formValue of second question is same as option id from found secondQuestion
          if (this.formValues.secondQuestion === option.id) {
            //adding option text of selected option to selectedSecondQuestion
            this.selectedSecondQuestion.textOption = option.option_text
            //adding price of selected option to selectedSecondQuestion
            this.selectedSecondQuestion.price = option.price
        } 
        });
        this.totalPrice.push(this.selectedSecondQuestion.price)
        // THIRD QUESTION
        //find third question
        const thirdQuestion = this.formData.data.find(x=> x.name === 'thirdQuestion');
        //set questionText for selectedThirdQuestion
        this.selectedThirdQuestion.questionText = thirdQuestion.question_text
        //loop through thirdQuestion question options
        thirdQuestion.question_options.forEach(option => {
        //check if formValue of third question is same as option id from found thirdQuestion
          if (this.formValues.thirdQuestion === option.id) {
            //adding option text of selected option to selectedThirdQuestion
            this.selectedThirdQuestion.textOption = option.option_text
            //adding price of selected option to selectedThirdQuestion
            this.selectedThirdQuestion.price = option.price
          }
        });
        this.totalPrice.push(this.selectedThirdQuestion.price)

        // //FOURTH QUESTION
          //find forth question
          const fourthQuestion = this.formData.data.find(x=> x.name === 'fourthQuestion');
          //set questionText for selectedThirdQuestion
          this.selectedFourthQuestion.questionText = fourthQuestion.question_text
          //loop through fourthQuestion question options
          fourthQuestion.question_options.forEach(option => {
                //check if formValues of thirdQuestion are certain numbers
                // and with that we know if the form is for ENTREPRENEUR, ASSOC or else DOO
                if (this.formValues.fourthQuestion === option.id) {
                  if(this.formValues.thirdQuestion === 36 || this.formValues.thirdQuestion === 37 || this.formValues.thirdQuestion === 38 || this.formValues.thirdQuestion === 92){
                  //adding option text of selected option to selectedFourthQuestion
                    this.selectedFourthQuestion.textOption = option.option_text
                    //adding price of selected option to selectedFourthQuestion
                    this.selectedFourthQuestion.price = option.price
                  }
                  else {
                    //adding option text of selected option to selectedFourthQuestion
                    this.selectedFourthQuestion.textOption = option.option_text
                    //adding price of selected option to selectedFourthQuestion
                    this.selectedFourthQuestion.price = option.price
                  }
                } 
          });  
        this.totalPrice.push(this.selectedFourthQuestion.price)
        // //FIFTH QUESTION
        //find fifth question
        const fifthQuestion = this.formData.data.find(x=> x.name === 'fifthQuestion');
        //set questionText for selectedFifthQuestion
        this.selectedFifthQuestion.questionText = fifthQuestion.question_text
        //loop through fifthQuestion question options
          fifthQuestion.question_options.forEach (option => {
        //check if formValue of fifth question is same as option id from found fifthQuestion
            if (this.formValues.fifthQuestion === option.id) {
              //adding option text of selected option to selectedFifthQuestion
              this.selectedFifthQuestion.textOption = option.option_text
              //adding price of selected option to selectedFifthQuestion
              this.selectedFifthQuestion.price = option.price
            }
        });
        this.totalPrice.push(this.selectedFifthQuestion.price)
        // //SIXTH QUESTION
        //find sixth question
        const sixthQuestion = this.formData.data.find(x=> x.name === 'sixthQuestion');
        //set questionText for selectedSixthQuestion
        this.selectedSixthQuestion.questionText = sixthQuestion.question_text
        //loop through sixthQuestion question options
        sixthQuestion.question_options.forEach(option => {
        //check if formValue of sixth question is same as option id from found sixthQuestion
          if (this.formValues.sixthQuestion === option.id) {
            //adding option text of selected option to selectedSixthQuestion
            this.selectedSixthQuestion.textOption = option.option_text
            //adding price of selected option to selectedSixthQuestion
            this.selectedSixthQuestion.price = option.price
          }
        });
        this.totalPrice.push(this.selectedSixthQuestion.price)
        // //SEVENTH QUESTION
        //find seventh question
        const seventhQuestion = this.formData.data.find(x=> x.name === 'seventhQuestion');
        //set questionText for selectedSeventhQuestion
        this.selectedSeventhQuestion.questionText = seventhQuestion.question_text
        //loop through seventhQuestion question options
        seventhQuestion.question_options.forEach(option => {
          //check if formValue of seventh question is same as option id from found seventhQuestion
          if (this.formValues.seventhQuestion === option.id) {
            //adding option text of selected option to selectedSeventhQuestion
            this.selectedSeventhQuestion.textOption = option.option_text
            //adding price of selected option to selectedSeventhQuestion
            this.selectedSeventhQuestion.price = option.price
          }
        });
        this.totalPrice.push(this.selectedSeventhQuestion.price)
        // //EIGHTH QUESTION
        //find eighth question
        const eighthQuestion = this.formData.data.find(x=> x.name === 'eighthQuestion');
        //set questionText for selectedEighthQuestion
        this.selectedEighthQuestion.questionText = eighthQuestion.question_text
        //loop through eighthQuestion question options
        eighthQuestion.question_options.forEach(option => {
          //check if formValue of eighth question is same as option id from found eighthQuestion
          if (this.formValues.eighthQuestion === option.id){
            //adding option text of selected option to selectedEighthQuestion
            this.selectedEighthQuestion.textOption = option.option_text
            //adding price of selected option to selectedEighthQuestion
            this.selectedEighthQuestion.price = option.price
          } 
        });
        this.totalPrice.push(this.selectedEighthQuestion.price)
        //NINTH QUESTION
        //find ninth question
        const ninthQuestion = this.formData.data.find(x=> x.name === 'ninthQuestion');
        //set questionText for selectedNinthQuestion
        this.selectedNinthQuestion.questionText = ninthQuestion.question_text
        //loop through ninthQuestion question options
        ninthQuestion.question_options.forEach(option => {
          //check if formValue of ninth question is same as option id from found ninthQuestion
          if (this.formValues.ninthQuestion === option.id){
            //adding option text of selected option to selectedNinthQuestion
            this.selectedNinthQuestion.textOption = option.option_text
            //adding price of selected option to selectedNinthQuestion
            this.selectedNinthQuestion.price = option.price
          }
        });
        this.totalPrice.push(this.selectedNinthQuestion.price)
        this.realTotalPrice = this.totalPrice.reduce((a,b) => a + b, 0)
        if (this.validateReCaptcha) {
          const submittedFormData = {
            //questions data
            typeOfFrom: this.selectedFormOption,
            firstQuestion: this.selectedFirstQuestion,
            secondQuestion: this.selectedSecondQuestion,
            thirdQuestion: this.selectedThirdQuestion,
            fourthQuestion: this.selectedFourthQuestion,
            fifthQuestion: this.selectedFifthQuestion,
            sixthQuestion: this.selectedSixthQuestion,
            seventhQuestion: this.selectedSeventhQuestion,
            eighthQuestion: this.selectedEighthQuestion,
            ninthQuestion: this.selectedNinthQuestion,
            email: this.formValues.email,
            comment: this.formValues.comment === '' ? '/' : this.formValues.comment,
            totalPrice: this.realTotalPrice,
            firstQSum: this.firstQSum
          }

          await this.setMailFormData(submittedFormData)
        }
    },
    handleInputs(val){
      if(val){
        this.deleteErrors();
        this.deleteEmailFormMessage()
      }
      else if(this.isActive === false){
        this.isActive = true
      }
    }
  },
  created(){
    if(this.$route.params.selectedOption){
      this.selectedButton = this.$route.params.selectedOption
      this.hideButtons = true
    }
    if(this.$route.params.from === 'home'){
    this.selectedButton = this.$route.params.selectedButton
    }
  },
  beforeDestroy(){
    this.deleteErrors();
    this.deleteEmailFormMessage()
    if(this.$route.params.selectedOption){
      this.selectedButton = ''
      this.hideButtons = false
    }
  }
}
</script>
<style>

.price-list-view{
  min-height: 1160px;
}
  .image-p-list-cover{
    background: url('../assets/hands.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.384);
    position:relative;
  }
  .image-p-list-cover-text{
    color: white;
    position: absolute;
    z-index: 10;
    margin:  4% 0% 0% 21%!important;
  }
.title{
  color: #404040;
}
.price-list-img{
  margin-bottom: 0px;
}
.price-list-component{
    margin-top: 0px;
}
.selected-buttons{
  padding-top: 100px;
}
.krug{border: none;color:#404040; border-radius: 10em !important; text-decoration: none;}
.entrepreneur-krug{
    color: #404040;
    border: none;
    background-color: #DABEBF;
    height: 320px;
    width: 320px;
    border-radius: 10em !important;
    padding: 0;
    margin:5px auto !important;

}

.entrepreneur-krug:hover{
  color: white;
  text-decoration: none;
  background-color: #314247 ;
  font-family: 'RobotoSlab-Medium', sans-serif !important;
  border-radius: 25em;
} 

.entrepreneur-card-body{
  height: 320px;
  width: 320px;
  border-radius: 25em !important;
  padding: 93px 10px 0 10px;
}
.hide{
    display: none !important;
}
.right-button{margin-left:22% !important;}
.error-message{
  width: 56%;
  background-color: #00A86B;
  color: #053A28
}

@media only screen and (max-width: 1280px){
  .price-list-img{
    margin-bottom: -19px;
  }
  .captcha{
    margin-left: 25% !important;
  }
  .image-p-list-cover-text{
    margin:  2% 0% 0% 20%!important;
  }
}
@media only screen and (max-width: 768px){
  .price-list-img{
    margin-bottom: 64px;
  }
  .captcha{
    margin-left: 30% !important;
  }
  .image-p-list-cover-text{
    margin:  4% 0% 0% 28%!important;
  }
  .image-p-list-cover-text h1{
    font-size: 23px;
  }
  .image-p-list-cover-text p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 540px){
  .price-list-img{
    margin-bottom: 101px;
  }
  .captcha{
    margin-left: 22% !important;
  }
  .image-p-list-cover-text{
    margin:  4% 0% 0% 22%!important;
  }
}

@media only screen and (max-width: 414px){
  .price-list-img{
    margin-bottom: 122px;
  }
  .error-message{
  width: 100%;
  }
  .captcha{
    margin-left: 13% !important;
  }
  .image-p-list-cover-text{
    margin:  4% 0% 0% 21%!important;
  }
  .image-p-list-cover-text h1{
    font-size: 19px;
  }
  .image-p-list-cover-text p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 375px){
  .price-list-img{
    margin-bottom: 128px;
  }
  .captcha{
    margin-left: 8% !important;
  }
  .image-p-list-cover-text{
    margin:  4% 0% 0% 18%!important;
  }
}
@media only screen and (max-width: 360px){
  .price-list-img{
    margin-bottom: 131px;
  }
  .image-p-list-cover-text h1{
    font-size: 17px;
  }
  .image-p-list-cover-text p {
    font-size: 10px;
  }
}
@media only screen and (max-width: 320px){
  .price-list-img{
    margin-bottom: 137px;
  }
  .captcha{
  margin-left: 3% !important;
  }

}
@media only screen and (max-width: 280px){
  .captcha{
    margin-left: 0% !important;
    transform:scale(0.925);
    transform-origin:0 0;
  }
  .image-p-list-cover-text{
    margin:  4% 0% 0% 1%!important;
  }
  .image-p-list-cover-text h1{
    font-size: 15px;
  }
  .image-p-list-cover-text p {
    font-size: 8px;
  }
  .entrepreneur-krug{
    height: 280px;
    width: 280px;
  }
  .entrepreneur-card-body{
    height: 280px;
    width: 280px;
  }

}
</style>